<template>
  <div id="app">
    <div class="container chat-app">
      <div class="chat-box">
        <div class="chat-history">
          <div v-for="message in messages" :key="message['_id']">
            <div class="message-wrapper" :class="{'text-right is-user': formatMessage(message).isUser}">
              <div class="d-flex align-items-center" :class="{'flex-row-reverse': formatMessage(message).isUser}">
                <div>
                  <div class="message-content" :class="{'bg-primary text-white': formatMessage(message).isUser}" v-html="formatMessage(message).text" />
                  <small class="text-muted">{{ formatMessage(message).timestamp }}</small>
                </div>
              </div>
            </div>
            <div style="clear: both;"></div>
          </div>
        </div>
        <div class="mt-3">
          <div class="chat-input">
              <div class="input-wrapper">
                <textarea
                    placeholder=""
                    v-model="newMessage"
                    @keydown.enter.exact.prevent="sendMessageInternal"
                    @keydown.enter="newLine"></textarea>
              </div>
              <span class="button-wrapper">
                <button class="chat-send" @click="sendMessageInternal"><v-icon name="paper-plane"/></button>
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from "vue-awesome/components/Icon"
  export default {
    name: "Chat",
    components: {
      'v-icon': Icon
    },
    props: {
      messages: { type: Array },
      sendMessage: { type: Function },
    },
    data() {
      return {
        newMessage: '',
        takeoverPrefix: '** LO Takeover: ',
      };
    },
    computed: {
    },
    watch: {
      messages(newMessages, oldMessages) {
        // Only scroll to bottom if the number of messages has increased
        if (newMessages.length > oldMessages.length) {
          this.scrollToBottom();
        }
      },
    },
    methods: {
      formatMessage(message) {
        let message_content = message.message;
        // replace /n with <br>
        message_content = message_content.replace(/(?:\r\n|\r|\n)/g, '<br>');
        // wrap hyperlinks, stop pattern on space or <br>
        message_content = message_content.replace(/(https?:\/\/[^\s<]+)/g, '<a href="$1" target="_blank">$1</a>');
        let takeOverFlag=false
        // remove takeover prefix
        if (message_content.startsWith(this.takeoverPrefix)) {
          message_content = message_content.substring(this.takeoverPrefix.length);
          takeOverFlag=true
        }
        return {
          // incoming message format
          // {
          //     "message": "** LO Takeover: hi, how are you?",
          //     "timestamp": "2024-08-10T16:11:15.846Z",
          //     "sender": "system",
          //     "status": "sent",
          //     "_id": "66b791241e2ada2a2f1c927a"
          // },
          text: message_content,
          timestamp: new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          isUser: message.sender=="system" ? true : false,
          takeOverFlag
        }
      },
      sendMessageInternal() {
        if (this.newMessage) {
          this.newMessage.trim()
          console.log("sendMessageInternal", this.newMessage);
          this.messages.push({
            message: this.newMessage,
            timestamp: new Date().toISOString(),
            sender: 'system',
            status: 'sent',
            _id: Math.random().toString(36).substr(2, 9),
          });
          this.sendMessage(this.newMessage.trim());
          this.newMessage = '';
          this.scrollToBottom();
        }
      },
      scrollToBottom() {
        this.$nextTick(() => {
          const chatHistory = this.$el.querySelector('.chat-history');
          chatHistory.scrollTop = chatHistory.scrollHeight;
        });
      },
      newLine(event) {
        if (!event.shiftKey) {
          event.preventDefault();
          this.sendMessage();
        }
      },
    },
    mounted() {
      this.scrollToBottom();
    },
  };
</script>

<style scoped>
.chat-box {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
}
.message {
  margin-bottom: 20px;
}
.message-content {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  line-height: normal;
  a {
    color: white;
    text-decoration: underline;
  }
}
.text-muted{
  font-size: 12px;
}
.text-right{
  float: right;
}
.bg-primary{
  background-color: #7367f0;
}
.chat-app{
  background-color: #f8f9fa;
  max-width: fit-content;
}
.chat-history{
  max-height: 500px;
  overflow-y: scroll;
}
.chat-input{
  width: 100%;
  background: #fff;
  padding: 10px;
  input{
    width: 85%;
    padding: 0.426rem 0.9375rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1;
    color: #444050;
    appearance: none;
    background-color: transparent;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
    border: none;
  }
  input:focus-visible{
    outline: none;
    border: none;
  }
  .button-wrapper{
    width:40px;
    display: inline-block;
    float: right;
    .chat-send{
      font-size:17px;
      color: #7367f0;
      background: none;
      border: none;
      margin-top:7px;
    }
    button:focus-visible{
      outline: none;
      border: none;
    }
  }
  .input-wrapper{
    display: inline-block;
    width: calc(100% - 40px);
    textarea {
      display: inline-block;
      width: 85%;
      padding: 0.426rem 0.9375rem;
      font-size: 0.9375rem;
      font-weight: 400;
      line-height: 1;
      color: #444050;
      background-color: transparent;
      background-clip: padding-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      outline: none;
      border: none;
      resize: none;
      height: 35px; /* Adjust as needed */
      overflow-y: auto;
    }
    textarea:focus-visible {
      outline: none;
      border: none;
    }
  }
}
.message-wrapper{
  max-width: 80%;
}
</style>