<template>
  <div class="container-fluid">
    <div v-if="threads" class="row">
      <!-- Left Sidebar: Threads List -->
      <div :class="['col-sm-4', { 'd-none': !showList && isMobile }]">
        <h5 class="mt-3">Threads</h5>
        <div class="input-group mb-3">
          <select class="form-select" v-model="selectedBrand" @change="filterThreads">
            <option value="">All Brands</option>
            <option v-for="brand in brandList" :key="brand.id" :value="brand.id">{{ brand.name }}</option>
          </select>
        </div>
        <ul class="list-group threads-list">
          <li
              v-for="thread in filteredThreads"
              :key="thread._id"
              class="list-group-item list-group-item-action"
              @click="selectThread(thread)"
              :class="{ active: selectedThread && selectedThread._id === thread._id }"
          >
            {{ threadName(thread) }} - {{ getLastMessageTimestamp(thread) }}
            <span class="status-indicator" :class="getThreadStatus(thread)"><v-icon :name="getThreadStatus(thread)==='takeover' ? 'user-circle' : 'circle'" /></span>
          </li>
        </ul>
      </div>

      <!-- Right Sidebar: Messages -->
      <div :class="['col-sm-8', { 'd-none': showList && isMobile }]">
        <div v-if="selectedThread">
          <div>
            <button class="chat-back-btn" @click="clickBack"><v-icon name="arrow-left" /></button>
            <div class="options-dropdown btn-group">
              <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                &#x22EE;
              </button>
              <ul class="dropdown-menu dropdown-menu-right">
                <li><a href="#" @click="deleteThread">Delete</a></li>
                <li><a href="#" @click="takeOverThreadToggle">{{selectedThread.status == "takeover" ? "Release" : "Takeover"}}</a></li>
                <!-- <li><a href="#">Something else here</a></li>
                <li role="separator" class="divider"></li>
                <li><a href="#">Separated link</a></li> -->
              </ul>
            </div>
            <div style="clear: both;"></div>
          </div>
          <Chat :messages="selectedThread.messages" :send-message="sendMessage" />
        </div>
        <div v-else>
          <p>Select a thread to view messages.</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="loading">
        <p>Loading...</p>
      </div>
      <div v-else>
        <p>No threads found.</p>
      </div>
    </div>
  </div>
</template>

<script>

import Chat from "../components/Chat/index.vue";
import Icon from "vue-awesome/components/Icon"

export default {
  name: "ChatAdmin",
  components: {
    Chat,
    'v-icon': Icon
  },
  props: {
  },
  data() {
    const vm = this
    return {
      loading: false,
      threads: null,
      selectedThread: null,
      pollingThread: false,
      showList: true,
      windowWidth: window.innerWidth,
      dropdownOpen: false,
      selectedBrand: null,
      filteredThreads: []
    }
  },
  async mounted() {
    this.loading = true;
    await this.getThreads();
    this.filteredThreads = this.threads
    this.loading = false;
    this.pollSelectedThread();
    window.addEventListener('resize', this.updateWindowWidth);
  },
  computed: {
    isMobile() {
      return this.windowWidth < 768;
    },
    brandList() {
      // Get unique brands {id, name} from threads
      const brands = this.threads.map(thread => {
        return {
          id: thread.brand_id,
          name: thread.loan_officer.brand_name
        }
      });
      return brands.filter((brand, index, self) =>
        index === self.findIndex((t) => (
          t.id === brand.id && t.name === brand.name
        ))
      );
    }
  },
  methods: {
    sendMessage(message){
      if(message){
        BB.smsThread.reply({
          thread_id: this.selectedThread['_id'],
          message
        })
      }

    },
    threadName(thread){
      // if first_name + last_name is over 16 characters, add elipses
      let fullName = thread.first_name + " " + thread.last_name
      if(fullName.length > 20){
        return fullName.substring(0, 20) + "..."
      }
      return fullName
    },
    clickBack() {
      this.showList = true;
      this.selectedThread = null;
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    getThreadStatus(thread){
      if(thread.status=="disabled"){
        return "disabled"
      }
      else if(thread.status=="takeover"){
        return "takeover"
      }
      // if last message was more than 48 hours ago
      else if(thread.messages && (new Date(thread.messages[thread.messages.length-1].timestamp) < new Date(Date.now() - 48 * 60 * 60 * 1000))){
        return "stale"
      }
      else if(thread.messages && thread.messages.length==1 && thread.messages[thread.messages.length-1].sender=="system"){
        return "initial"
      }
      return "active";
    },
    async filterThreads(e) {
      if(this.selectedBrand){
        this.filteredThreads = this.threads.filter(thread => thread.brand_id === this.selectedBrand)
      }
      else{
        this.filteredThreads = this.threads
      }
    },
    async getThreads(){
      // single thread schema:
      //   "loan_officer": {
      //       "first_name": "USA Mortgage",
      //       "last_name": "Team",
      //       "email": "ameyer@dasacquisition.com",
      //       "phone": "",
      //       "nmls": "1586340",
      //       "brand_name": "USA Mortgages",
      //       "app_link": "https://headquarters.usa-mortgage.com/?loanapp&siteid=2728641155&lar=admin&workFlowId=39510",
      //       "brand_nmls": "3232",
      //       "calendar_link": "",
      //       "loid": 1930
      //   },
      //   "quote_info": {
      //       "loan_amount": 251989,
      //       "loan_purpose": "purchase",
      //       "property_type": "single_family_home",
      //       "zipcode": "",
      //       "credit_score": 780,
      //       "military_eligible": ""
      //   },
      //   "_id": "66bf94fce87e5e45321cd46f",
      //   "item_id": "45",
      //   "brand_id": 366184,
      //   "first_name": "Don",
      //   "last_name": "Trump Jr",
      //   "email": "dtrump@aol.com",
      //   "phone": "+18435090505",
      //   "dashboard_link": "https://l.bbridge.io/WAHn",
      //   "type": "subscription",
      //   "send_initial_summary": true,
      //   "status": "active",
      //   "messages": [
      //       {
      //           "message": "Hey, it's Katie with USA Mortgages. Thank you for your recent online submission! Here is a loan summary of your recent quote.\n\nI’ve also put together your custom rate dashboard to view rates 24/7.\n\nCheck it out: https://l.bbridge.io/WAHn\n\nNMLS 1586340, Text STOP to OptOut.\n\nIs this your first home or have you purchased before?\n",
      //           "timestamp": "2024-08-16T18:06:00.813Z",
      //           "sender": "system",
      //           "status": "sent",
      //           "_id": "66bf9509e87e5e45321cd471",
      //           "notes": []
      //       },
      //     ...
      //     ]
      //   }
      let threadResults = await BB.smsThread.find()
      if(threadResults.status==="error"){
        return
      }
      // filter out threads with no messages
      threadResults = threadResults.filter(thread => thread.messages.length > 0);
      // sort threads by most recent message in descending order
      threadResults = threadResults.sort((a, b) => {
        const dateA = new Date(a.messages[a.messages.length - 1].timestamp);
        const dateB = new Date(b.messages[b.messages.length - 1].timestamp);
        return dateB - dateA;
      });

      this.threads=threadResults
    },
    getLastMessageTimestamp(thread){
      const date = new Date(thread.messages[thread.messages.length - 1].timestamp);
      return date.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }).replace(',', ' @');
    },
    async refreshSelectedThread(){
      if(!this.selectedThread){
        return
      }
      let result=null;
      if(this.selectedThread.type=="subscription"){
        result = await BB.smsThread.get({
          subscription_id: this.selectedThread.item_id,
        })
      }
      else if(this.selectedThread.type=="lead"){
        result = await BB.smsThread.get({
          lead_id: this.selectedThread.item_id,
        })
      }
      if(result.status==="error"){
        return
      }
      if(result['_id']==this.selectedThread['_id']){
        this.selectedThread = result
        // update thread list
        this.threads = this.threads.map(thread => {
          if(thread['_id']==this.selectedThread['_id']){
            return this.selectedThread
          }
          return thread
        })
        this.filterThreads()
      }
    },
    selectThread(thread) {
      console.log("selecting thread: ",thread)
      this.showList = false;
      this.selectedThread = thread;
    },
    async deleteThread() {
      if(this.selectedThread){
        await BB.smsThread.delete(this.selectedThread['_id'])
        this.selectedThread = null;
        await this.getThreads();
        this.showList=true
      }
    },
    async takeOverThreadToggle() {
      if(this.selectedThread){
        if(this.selectedThread.status=="takeover"){
          await BB.smsThread.update({
            thread_id: this.selectedThread['_id'],
            status: "active"
          })
          this.selectedThread.status="active"
        }
        else{
          await BB.smsThread.update({
            thread_id: this.selectedThread['_id'],
            status: "takeover"
          })
          this.selectedThread.status="takeover"
        }
        // update selectedThread in threads list
        this.threads = this.threads.map(thread => {
          if(thread['_id']==this.selectedThread['_id']){
            return this.selectedThread
          }
          return thread
        })
        this.filterThreads()
      }
    },
    async pollSelectedThread() {
      while(true){
        // Poll the selected thread for new messages while pollingThread is true
        if(this.selectedThread){
          await this.refreshSelectedThread()
        }
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    }
  }
}
</script>

<style scoped>
.list-group-item.active {
  background-color: #5C52C0;
  border: 1px solid #5C52C0;
}
.chat-back-btn {
  cursor: pointer;
  border: none;
  background: transparent;
  display: none; /* Hide by default */
}
@media (max-width: 767px) {
  .chat-back-btn {
    display: inline-block; /* Show on mobile devices */
    margin-top:8px;
  }
  .col-sm-4.d-none {
    display: none !important; /* Hide left sidebar on mobile when showList is false */
  }
  .col-sm-8.d-none {
    display: none !important; /* Hide right sidebar on mobile when showList is true */
  }
}
.options-dropdown{
  float: right;
  margin-top:0px;
}
.threads-list{
  li.list-group-item{
    cursor: pointer;
    padding: 10px 5px !important;
  }
}
.status-indicator{
  float: right;
  &.active{
    color: #8BC34A;
  }
  &.disabled{
    color: #F44336;
  }
  &.initial{
    // color: #FFC107;
    color: #4DD0E1;
  }
  &.stale{
    color: #607D8B;
  }
  &.takeover{
    color: #4CAF50;
  }
}
</style>