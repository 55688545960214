/**
 * SMS Thread API wrapper
 */

const UPDATE_ENDPOINT = "/api/v1/thread"
const CREATE_ENDPOINT = "/api/v1/new-thread"
const GET_ENDPOINT = "/api/v1/sms-thread"
const REPLY_ENDPOINT = "/api/v1/thread-reply"
const DELETE_ENDPOINT = "/api/v1/thread"
const FIND_ENDPOINT = "/api/v1/sms-threads"

const bbSMSThread = {
    get(params, callback = null) {
        // subscription_id or lead_id is required
        if (!params.subscription_id && !params.lead_id) {
            console.log("subscription_id or lead_id is required")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }
        return $.get(GET_ENDPOINT, params, function(data) {
            if (callback && typeof callback === "function") {
                callback.call(this, data)
            }
        })
    },
    update(thread, callback) {
        // subscription_id or lead_id is required
        if (!thread.subscription_id && !thread.lead_id && !thread.thread_id) {
            console.log("thread_id, subscription_id or lead_id is required")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }

        return $.ajax({
            url: UPDATE_ENDPOINT,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(thread),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
    add(thread, callback) {
        // subscription_id or lead_id is required
        if (!thread.subscription_id && !thread.lead_id) {
            console.log("subscription_id or lead_id is required")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }
        return $.ajax({
            url: CREATE_ENDPOINT,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(thread),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
    delete(id, callback) {
        return $.ajax({
            url: DELETE_ENDPOINT + "?thread_id=" + id,
            type: "DELETE",
            contentType: "application/json",
            dataType: "json",
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
    reply(reply, callback = null) {
        // message and subscription_id required
        if ((!reply.lead_id && !reply.thread_id && !reply.subscription_id) || !reply.message) {
            console.log("id (thread_id, lead_id, subscription_id) and message is required")
            return new Promise((resolve, reject) => {
                reject("error")
            })
        }
        return $.ajax({
            url: REPLY_ENDPOINT,
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(reply),
            success(data) {
                if (callback && typeof callback === "function") {
                    callback.call(this, data)
                }
            },
            error(xhr, ajaxOptions, thrownError) {
                if (callback && typeof callback === "function") {
                    callback.call(this, thrownError)
                }
            }
        })
    },
    find(params, callback){
        return $.get(FIND_ENDPOINT, params, function(data) {
            if (callback && typeof callback === "function") {
                callback.call(this, data)
            }
        })
    }
}

export default bbSMSThread