<template>
  <div class="editorWrapper">
    <div style="height:calc(100% - 50px);">
      <div class="copy-wrapper" v-if="activeRowLocal.id">
        <input type="text" :value="activeRowLocal.dashboard_link">
      </div>
      <Tabs>
        <Tab name="Summary">
          <div class="summaryWrapper">
            <div v-if="!isLoading">
              <Card>
                <h5>Status</h5>
                <div class="btn-group" role="group" aria-label="">
                  <button type="button" @click="setStatus('active')" class="btn btn-default" :class="{active : subStatus==='active'}">Active</button>
                  <button type="button" @click="setStatus('disabled')" class="btn btn-default" :class="{active : subStatus==='disabled'}">Disabled</button>
                  <button type="button" @click="setStatus('deleted')" class="btn btn-default" :class="{active : subStatus==='deleted'}">Deleted</button>
                </div>
              </Card>
              <Card>
                <h5>Dashboard Link<a target="_blank" :href="activeRowLocal.dashboard_link"><i style="margin-left:10px;" class="fas fa-external-link-alt"></i></a></h5>
                <label></label><button @click="copyText">Copy</button>
              </Card>
              <Card>
                <h5>Email</h5>
                <div v-if="introEmail && introEmail.id">
                  <a target="_blank" :href="'/view-email/'+parseInt(introEmail.id).toString(36)">Introduction email</a>: <strong>{{introEmail.status}}</strong> as of {{asOfDate}}
                </div>
                <div v-else>
                  <button @click="sendIntroEmail">Send Introduction Email</button>
                </div>
              </Card>
              <Card>
                <h5>Loan Summary</h5>
                <button @click="printPDF">Print Loan Summary</button>
                <div style="margin-top:15px;" v-if="pdfResult && pdfResult.file">
                  <a target="_blank" :href="pdfResult.file">{{pdfResult.label}}</a>
                </div>
              </Card>
              <div>
                <button style="float:right; margin-right:25px; margin-top:15px;" @click="editSubscription">
                  <span v-if="isEditing"><i class="fas fa-check"></i></span>
                  <span v-else>Edit</span>
                </button>
                <div style="clear:both;"></div>
              </div>
              <Card>
                <h5>Contact Info</h5>
                <form>
                  <fieldset>
                    <label class="scenario-label">First Name</label>
                    <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.first_name"></span>
                    <span v-else>{{activeRowLocal.first_name}}</span>
                  </fieldset>
                  <fieldset>
                    <label class="scenario-label">Last Name</label>
                    <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.last_name"></span>
                    <span v-else>{{activeRowLocal.last_name}}</span>
                  </fieldset>
                  <fieldset>
                    <label class="scenario-label">Email</label>
                    <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.email"></span>
                    <span v-else>{{activeRowLocal.email}}</span>
                  </fieldset>
                  <fieldset>
                    <label class="scenario-label">Phone</label>
                    <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.phone"></span>
                    <span v-else>{{activeRowLocal.phone}}</span>
                  </fieldset>
                </form>
              </Card>
              <Card>
                <h5>
                  Loan Scenario
                </h5>
                <fieldset>
                  <label class="scenario-label">Loan Purpose</label>
                  <span v-if="isEditing" class="scenario-value">
                    <select v-model="activeRowLocal.quote_info.loan_purpose">
                      <option value="purchase">Purchase</option>
                      <option value="refinance">Refinance</option>
                    </select>
                  </span>
                  <span v-else>{{activeRowLocal.quote_info.loan_purpose}}</span>
                </fieldset>
                <fieldset v-if="activeRowLocal.quote_info.loan_purpose=='refinance'">
                  <label class="scenario-label">Cash Out</label>
                  <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.quote_info.cash_out"></span>
                  <span v-else>{{activeRowLocal.quote_info.cash_out}}</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">Loan Type</label>
                  <span v-if="isEditing" class="scenario-value">
                    <select v-model="activeRowLocal.quote_info.loan_type">
                      <option value="conventional">Conventional</option>
                      <option value="va">VA</option>
                      <option value="usda">USDA</option>
                      <option value="fha">FHA</option>
                      <option value="arm">ARM</option>
                    </select>
                  </span>
                  <span v-else>{{activeRowLocal.quote_info.loan_type}}</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">Loan Term</label>
                  <span v-if="isEditing" class="scenario-value">
                    <select v-model="activeRowLocal.quote_info.loan_term">
                      <option value="30">30 Year Fixed</option>
                      <option value="20">20 Year Fixed</option>
                      <option value="15">15 Year Fixed</option>
                    </select>
                  </span>
                  <span v-else>{{ activeRowLocal.quote_info.loan_term ? ( activeRowLocal.quote_info.loan_term > 40 ? activeRowLocal.quote_info.loan_term/12 : activeRowLocal.quote_info.loan_term ) : "" }}</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">ARM Term</label>
                  <span v-if="isEditing" class="scenario-value">
                    <select v-model="activeRowLocal.quote_info.arm_term">
                      <option value="">N/A</option>
                      <option value="5">5/1 ARM</option>
                      <option value="7">7/1 ARM</option>
                      <option value="10">10/1 ARM</option>
                    </select>
                  </span>
                  <span v-else>{{ activeRowLocal.quote_info.arm_term ? activeRowLocal.quote_info.arm_term +"/1 ARM" : "None" }}</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">Loan Amount</label>
                  <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.quote_info.loan_amount"></span>
                  <span v-else>{{activeRowLocal.quote_info.loan_amount}}</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">Property Value</label>
                  <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.quote_info.list_price"></span>
                  <span v-else>{{activeRowLocal.quote_info.list_price}}</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">Credit Score</label>
                  <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.quote_info.credit_score"></span>
                  <span v-else>{{activeRowLocal.quote_info.credit_score}}</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">Property Type</label>
                  <span v-if="isEditing" class="scenario-value">
                    <select v-model="activeRowLocal.quote_info.property_type">
                      <option value="single_family_home">Single Family Home</option>
                      <option value="townhome">Townhome</option>
                      <option value="condominium">Condominium</option>
                      <option value="multi_tenant_home">Multi Family</option>
                    </select>
                  </span>
                  <span v-else>{{activeRowLocal.quote_info.property_type}}</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">Property Usage</label>
                  <span v-if="isEditing" class="scenario-value">
                    <select v-model="activeRowLocal.quote_info.residency_type">
                      <option value="primary_home">Primary Residence</option>
                      <option value="second_home">Secondary Residence</option>
                      <option value="rental_home">Rental</option>
                    </select>
                  </span>
                  <span v-else>{{activeRowLocal.quote_info.residency_type}}</span>
                </fieldset>
              </Card>
              <Card>
                <h5>Property Location</h5>
                <fieldset>
                  <label class="scenario-label">Street Address</label>
                  <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.quote_info.location.street_address"></span>
                  <span v-else>{{activeRowLocal.quote_info.location.street_address}}</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">City</label>
                  <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.quote_info.location.city"></span>
                  <span v-else>{{activeRowLocal.quote_info.location.city}}</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">State</label>
                  <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.quote_info.location.state"></span>
                  <span v-else>{{activeRowLocal.quote_info.location.state}}</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">County</label>
                  <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.quote_info.location.county"></span>
                  <span v-else>{{activeRowLocal.quote_info.location.county}}</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">Zip Code</label>
                  <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.quote_info.location.zipcode"></span>
                  <span v-else>{{activeRowLocal.quote_info.location.zipcode}}</span>
                </fieldset>
              </Card>
              <Card>
                <h5>Loan Officer Fields</h5>
                <div style="margin-bottom:15px;">
                  <fieldset>
                    <label class="scenario-label">Loan Officer</label>
                    <span v-if="isEditing" class="scenario-value">
                        <select
                          class="select-lo"
                          v-model="activeRowLocal.loid"
                          @change="selectLo"
                          id="select-lo"
                        >
                          <option disabled value="">Please select one</option>
                          <option
                            v-for="lo in los"
                            :value="lo.uid"
                            :key="lo.name"
                          >
                            {{ lo.name }}
                          </option>
                        </select>
                    </span>
                    <span v-else>{{activeRowLocal.lo.name}}</span>
                  </fieldset>
                </div>
                <fieldset>
                  <label class="scenario-label">Annual Taxes</label>
                  <span v-if="isEditing" class="scenario-value"><input type="text" v-model="activeRowLocal.quote_info.admin_fields.taxes"></span>
                  <span v-else-if="activeRowLocal.quote_info.admin_fields && activeRowLocal.quote_info.admin_fields.taxes && activeRowLocal.quote_info.admin_fields.taxes > 0">{{activeRowLocal.quote_info.admin_fields.taxes}}</span>% | $<span>{{(Number((activeRowLocal.quote_info.admin_fields.taxes/100*activeRowLocal.quote_info.list_price)).toFixed(2))}}/yr</span>
                </fieldset>
                <fieldset>
                  <label class="scenario-label">Annual Home Insurance</label>
                  <span v-if="isEditing" class="scenario-value"><input type="text" @change="fieldChange" v-model="activeRowLocal.quote_info.admin_fields.insurance"></span>
                  <span v-else-if="activeRowLocal.quote_info.admin_fields && activeRowLocal.quote_info.admin_fields.insurance && activeRowLocal.quote_info.admin_fields.insurance > 0">{{activeRowLocal.quote_info.admin_fields.insurance}}</span>% | $<span>{{(Number((activeRowLocal.quote_info.admin_fields.insurance/100*activeRowLocal.quote_info.list_price)).toFixed(2))}}/yr</span>
                </fieldset>
              </Card>
            </div>
            <div v-else>
              Loading...
            </div>
          </div>
        </Tab>
        <Tab name="Details">
          <div class="editorContainer">
            <LoaderPlaceholder v-if="isLoading" type="circle" />
            <vjsoneditor
                v-else
                v-model="activeRowLocal"
                :options="options"
                :plus="true"
            />
          </div>
        </Tab>
        <Tab name="Emails" class="emails-tab">
          <div class="container" style="padding-top:15px;">
            <div class="row">
              <div v-if="!isLoading">
                <div class="col-md-12">
                  <div v-if="emailLog && emailLog.length">
                    <ul class="list-group">
                      <li class="list-group-item" v-for="(item, index) in emailLog">
                        <a target="_blank" :href="'/view-email/'+parseInt(item.email_id).toString(36)">Rate Alert</a> @ {{new Date(item.timestamp*1000).toLocaleString()}}
                        <span class="badge">{{(item.status=="2" ? "Opened" : item.status=="3" ? "Clicked" : "Unopened")}}</span>
                      </li>
                    </ul>
                  </div>
                  <div v-else>
                    <div class="well">No rate alerts have been sent yet.</div>
                  </div>
                </div>
              </div>
              <div v-else>
                Loading...
              </div>
            </div>
          </div>
        </Tab>
        <Tab name="History" class="events-tab">
          <div class="container" style="padding-top:15px;">
            <div class="row">
              <div v-if="!isLoading">
                <div class="col-md-12">
                <div v-if="eventsLog && eventsLog.length">
                  <ul class="list-group">
                    <li class="list-group-item" v-for="(item, index) in eventsLog">
                      <span>{{humanize(item.type)}}</span> @ {{new Date(item.timestamp*1000).toLocaleString()}}
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <div class="well">There are no events for this subscription yet.</div>
                </div>
              </div>
              </div>
              <div v-else>
                Loading...
              </div>
            </div>
          </div>
        </Tab>
        <Tab name="Followup" class="events-tab">
          <div class="container" style="padding-top:15px;">
            <div class="row">
              <div v-if="!isLoading">
                <div class="col-md-12">
                  <div v-if="smsThread">
                    <Chat :messages="smsThread.messages" :send-message="threadReply" />
                  </div>
                  <div v-else>
                    <div v-if="!creatingThread"><button @click="createThread">Send SMS Followup</button></div>
                    <div v-else>Sending Followup...</div>
                  </div>
                </div>
              </div>
              <div v-else>
                Loading...
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
    <div class="footer">
      <button class="btn btn-modern closeBtn" @click="closePanel">Close</button>
      <button v-if="formChanged" class="btn btn-modern saveBtn" @click="saveSubscription">Save</button>
    </div>
  </div>

</template>

<script>
import vjsoneditor from "v-jsoneditor"
import { Tabs, Tab } from "../Tabs"
import CalcCard from "../CalcCard"
import Moment from "moment"
import Card from "../Card"
import md5 from "js-md5"
import Chat from "../Chat/index.vue";

export default {
  name: "LeadPanel",
  components: {
        vjsoneditor,
        Tabs,
        Tab,
        CalcCard,
        Card,
        Chat
    },
  data: function() {
    return {
      activeRowLocal: {},
      isLoading: true,
      introEmail: null,
      isEditing: false,
      hash: null,
      pdfResult: null,
      subStatus: null,
      emailLog: null,
      eventsLog: null,
      los: null,
      smsThread: null,
      creatingThread: false,
      polling: true
    }
  },
  props: {
    options: { type: Object },
    onCreated: { type: Function }
  },
  updated() {
    if(!this.activeRowLocal){
      return null;
    }
    if(!this.activeRowLocal.quote_info){
      return null;
    }
    let money_fields = ["loan_amount","cash_out","list_price"]
    money_fields.forEach(field=>{
      if(this.activeRowLocal.quote_info[field]){
        if(typeof this.activeRowLocal.quote_info[field] === "string"){
          this.activeRowLocal.quote_info[field] = this.activeRowLocal.quote_info[field].replace(/\$/g, "")
          this.activeRowLocal.quote_info[field] = this.activeRowLocal.quote_info[field].replace(/,/g, "")
        }
      }
    })
  },
  computed: {
    requestJSONString(){
        return JSON.stringify(this.activeRowLocal.full_request)
    },
    asOfDate(){
      return Moment.unix(this.introEmail.last_update).calendar(null, {
          sameElse: "MMM D, YYYY"
      })
    },
    formChanged(){
      if(!this.hash)return false
      return this.hash!=md5(JSON.stringify(this.activeRowLocal))
    }
  },
  created() {
    this.onCreated().then(async activeRow => {
      this.activeRowLocal=activeRow
      if(!this.activeRowLocal.quote_info.admin_fields || (Array.isArray(this.activeRowLocal.quote_info.admin_fields) && this.activeRowLocal.quote_info.admin_fields.length===0))this.activeRowLocal.quote_info=Object.assign({}, activeRow.quote_info, {admin_fields: {}});
      if(!this.activeRowLocal.quote_info.location)this.activeRowLocal.quote_info=Object.assign({}, activeRow.quote_info, {location: {}});
      //this.activeRowLocal = {...activeRow, location: {}, admin_fields: {}}
      //if(!this.activeRowLocal.quote_info.admin_fields)this.$set(this.activeRowLocal.quote_info, 'admin_fields', {})
      await this.getIntroEmail();
      await this.getTaxesAndInsurance();
      await this.getEmailLog();
      await this.getEventsLog();
      await this.getLOs(this.activeRowLocal.lo.bank_nid)
      await this.getSMSThread()
      this.pollThread()
      this.hash = md5(JSON.stringify(this.activeRowLocal))
      this.subStatus = ( parseInt(this.activeRowLocal.deleted) ? 'deleted' : ( parseInt(this.activeRowLocal.active) ? 'active' : 'disabled' ) )
      this.isLoading = false
    })
  },
  beforeDestroy() {
    this.polling = false;
  },
  methods: {
      closePanel() {
          this.$emit('closePanel', {});
          this.pdfResult=null
          this.subStatus=null
          this.polling=false
          this.smsThread=null
      },
      gotoPricingTool(){
          window.open("/pricing-tool?log_id="+this.activeRowLocal.id, '_blank');
          return;
      },
      copyText(){
          /* Get the text field */
          var copyText = $(".copy-wrapper input")[0];

          if(!copyText.value){
              return;
          }

          /* Select the text field */
          copyText.select();
          copyText.setSelectionRange(0, 99999); /* For mobile devices */

          /* Copy the text inside the text field */
          document.execCommand("copy");

          /* Alert the copied text */
          BB.Toastr.success("Dashboard link copied")
      },
      async getIntroEmail(){
          let result=await $.get("/api/v1/subscription-emails?type=intro&email="+this.activeRowLocal.email)
          if(result.id){
            this.introEmail=result;
          }
      },
      async getTaxesAndInsurance(){
          let result=await $.get("/api/v1/taxes-and-insurance?zipcode="+this.activeRowLocal.quote_info.location.zipcode+"&list_price="+this.activeRowLocal.quote_info.list_price)
          if(result.taxes && !this.activeRowLocal.quote_info.admin_fields.taxes){
            this.activeRowLocal.quote_info.admin_fields.taxes=Number((result.taxes*1200)/this.activeRowLocal.quote_info.list_price).toFixed(2);
          }
          if(result.insurance && !this.activeRowLocal.quote_info.admin_fields.insurance){
            this.activeRowLocal.quote_info.admin_fields.insurance=Number((result.insurance*1200)/this.activeRowLocal.quote_info.list_price).toFixed(2);
          }
      },
      async editSubscription(){
          if(this.isEditing){
              this.isEditing=false;
          }
          else{
              this.isEditing=true;
          }
      },
      fieldChange(e){
        console.log(e)
      },
      async saveSubscription(){
        BB.subscription.update(this.activeRowLocal)
        this.hash=md5(JSON.stringify(this.activeRowLocal))
      },
      async sendIntroEmail(){
        if(!this.activeRowLocal.id){
          BB.Toastr.error("Something went wrong, reload the page")
        }
        BB.subscription.sendIntroEmail(this.activeRowLocal.id)
        await this.getIntroEmail()
        BB.Toastr.success("Introduction Email Sent!")
      },
      async printPDF(){
        let quoteParams={
          loid: this.activeRowLocal.loid
        }
        this.pdfResult=null
        BB.Toastr.info("Preparing Loan Summary...")
        quoteParams = Object.assign(quoteParams,this.activeRowLocal.quote_info)
        const quote=await BB.rateflow.priceCustom(quoteParams)
        if(quote[0].quote_id){
          this.pdfResult=await $.get("/api/v1/loan-summary-pdf?subscription_id="+this.activeRowLocal.id+"&quote_id="+quote[0].quote_id)
          BB.Toastr.success("Loan Summary PDF ready")
        }
        else{
          BB.Toastr.error("Quote failed - Change the values and try again")
        }
      },
      async setStatus(status){
        switch (status) {
          case "active":
            this.activeRowLocal.active="1";
            this.activeRowLocal.deleted="0";
            break;
          case "deleted":
            this.activeRowLocal.deleted="1";
            this.activeRowLocal.active="0";
            break;
          case "disabled":
            this.activeRowLocal.active="0";
            this.activeRowLocal.deleted="0";
            break;
        }
        this.subStatus=status;
        //this.saveSubscription();
      },
      async getEmailLog(){
        let emails=await BB.subscription.getEmailLog({id:this.activeRowLocal.id})
        this.emailLog=emails.data
      },
      async getEventsLog(){
        this.eventsLog=(await $.get("/api/v1/subscription-events?subscription_id="+this.activeRowLocal.id)).data;
      },
      async getSMSThread(){
        let smsThread=(await $.get("/api/v1/sms-thread?subscription_id="+this.activeRowLocal.id));
        if(!smsThread || !smsThread['_id']){
          return;
        }
        this.smsThread=smsThread
      },
      async threadTakeover(){
        let result=await $.post("/api/v1/thread", {subscription_id: this.activeRowLocal.id, status: 'takeover'})
      },
      async threadReactivate(){
        let result=await $.post("/api/v1/thread", {subscription_id: this.activeRowLocal.id, status: 'active'})
      },
      async createThread(){
        this.creatingThread=true
        let result=await $.ajax({
          url: "/api/v1/new-thread",
          type: "POST",
          contentType: "application/json",
          dataType: "json",
          data: JSON.stringify({
            subscription_id: this.activeRowLocal.id,
          }),
        })
        if(result.status=="error"){
          BB.Toastr.error("Followup not sent")
        }
        await this.getSMSThread()
        this.creatingThread=false
      },
      async pollThread(){
        while(this.polling){
          if(this.smsThread && this.smsThread["_id"]){
            await this.getSMSThread()
          }
          await new Promise(resolve => setTimeout(resolve, 5000));
        }
      },
      async threadReply(message){
        if(!message)return;
        if(!this.smsThread || !this.smsThread["_id"]){
          return
        }
        console.log("replying to thread", message)
        // send post using $.ajax
        $.ajax({
            url: "/api/v1/thread-reply",
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify({
              message,
              subscription_id: this.activeRowLocal.id,
            }),
        })
        //this.getSMSThread()
      },
      selectLo(val){
        console.log(val.currentTarget)
      },
      async getLOs(brand_id) {
        if(!brand_id)return
        let result = null
        try {
          result = await BB.brandManage.getMembers(
              brand_id
          )
        } catch (e) {
          console.log("something went wrong getting available owners")
          return
        }
        this.los = result.members
      },
      humanize(str) {
        let i, frags = str.split('_');
        for (i=0; i<frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
      },
  }

}
</script>

<style lang="scss">
  .editorWrapper{
    height: 100%;
    position: relative;
    margin: 0px;
    overflow: hidden;
  }
  .summaryWrapper{
    margin-bottom: 20px;
  }
  .editorContainer{
    height: calc(100% - 50px);
    overflow: scroll;
  }
  .jsoneditor-container{
    div.jsoneditor{
      border: none;
    }
    .jsoneditor-menu{
      top: 0px;
      z-index: 900
    }
  }
  .footer{
    height: 50px;
    position: relative;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    width:100%;
    border-top:1px solid #eee;
    bottom: 0px;
    .btn{
      margin-top:5px;
      &.copyBtn{
        background: linear-gradient(0deg, #7e57c2 0%, #9883be 100%);
        border: 1px solid #9883be;
      }
    }
  }
  .tabs-component{
    height:100%;
  }
  .tabs-component-panels{
    padding: 0px !important;
    height: 100%;
    .tabs-component-panel{
      height: calc(100% - 55px);
      overflow-y: scroll;
    }
  }
  .tabs-component-tabs{
    margin-left:0px !important;
  }
  .label{
    color: #7c7c8d;
  }
  .copy-wrapper{
    //display:none;
    position: absolute;
    right:-9999px;
    input{
      //visibility: hidden;
    }
  }
  .card{
    margin: 15px 15px 0px 15px;
  }
  h5 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  button{
    cursor: pointer;
  }
  a{
    cursor: pointer;
  }
  .scenario-value{
    margin-left:10px;
  }
  .scenario-label{
    font-weight:bold;
    padding: 5px 0px;
  }
  .scenario-edit{
    position: absolute;
    right: 15px;
    top: 18px;
  }
  .scenario-save{
    position: absolute;
    right: 46px;
    top: 18px;
  }
  fieldset{
    height: 22px;
    margin-top: 5px;
  }
  .saveBtn{
    position: absolute;
    right: 10px;
  }
  .emails-tab, .events-tab {
    @media (min-width: 1200px) {
      .container {
        width: auto !important;
      }
    }
    @media (min-width: 992px){
      .container {
        width: auto !important;
      }
    }
    @media (min-width: 768px){
      .container {
        width: auto !important;
      }
    }
  }

</style>

