var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "editorWrapper" }, [
    _c(
      "div",
      { staticStyle: { height: "calc(100% - 50px)" } },
      [
        _vm.activeRowLocal.id
          ? _c("div", { staticClass: "copy-wrapper" }, [
              _c("input", {
                attrs: { type: "text" },
                domProps: { value: _vm.activeRowLocal.dashboard_link }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "Tabs",
          [
            _c("Tab", { attrs: { name: "Summary" } }, [
              _c("div", { staticClass: "summaryWrapper" }, [
                !_vm.isLoading
                  ? _c(
                      "div",
                      [
                        _c("Card", [
                          _c("h5", [_vm._v("Status")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "btn-group",
                              attrs: { role: "group", "aria-label": "" }
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-default",
                                  class: { active: _vm.subStatus === "active" },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      _vm.setStatus("active")
                                    }
                                  }
                                },
                                [_vm._v("Active")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-default",
                                  class: {
                                    active: _vm.subStatus === "disabled"
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      _vm.setStatus("disabled")
                                    }
                                  }
                                },
                                [_vm._v("Disabled")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-default",
                                  class: {
                                    active: _vm.subStatus === "deleted"
                                  },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      _vm.setStatus("deleted")
                                    }
                                  }
                                },
                                [_vm._v("Deleted")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("Card", [
                          _c("h5", [
                            _vm._v("Dashboard Link"),
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: _vm.activeRowLocal.dashboard_link
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-external-link-alt",
                                  staticStyle: { "margin-left": "10px" }
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("label"),
                          _c("button", { on: { click: _vm.copyText } }, [
                            _vm._v("Copy")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("Card", [
                          _c("h5", [_vm._v("Email")]),
                          _vm._v(" "),
                          _vm.introEmail && _vm.introEmail.id
                            ? _c("div", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href:
                                        "/view-email/" +
                                        parseInt(_vm.introEmail.id).toString(36)
                                    }
                                  },
                                  [_vm._v("Introduction email")]
                                ),
                                _vm._v(": "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.introEmail.status))
                                ]),
                                _vm._v(
                                  " as of " +
                                    _vm._s(_vm.asOfDate) +
                                    "\n              "
                                )
                              ])
                            : _c("div", [
                                _c(
                                  "button",
                                  { on: { click: _vm.sendIntroEmail } },
                                  [_vm._v("Send Introduction Email")]
                                )
                              ])
                        ]),
                        _vm._v(" "),
                        _c("Card", [
                          _c("h5", [_vm._v("Loan Summary")]),
                          _vm._v(" "),
                          _c("button", { on: { click: _vm.printPDF } }, [
                            _vm._v("Print Loan Summary")
                          ]),
                          _vm._v(" "),
                          _vm.pdfResult && _vm.pdfResult.file
                            ? _c(
                                "div",
                                { staticStyle: { "margin-top": "15px" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href: _vm.pdfResult.file
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.pdfResult.label))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-right": "25px",
                                "margin-top": "15px"
                              },
                              on: { click: _vm.editSubscription }
                            },
                            [
                              _vm.isEditing
                                ? _c("span", [
                                    _c("i", { staticClass: "fas fa-check" })
                                  ])
                                : _c("span", [_vm._v("Edit")])
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticStyle: { clear: "both" } })
                        ]),
                        _vm._v(" "),
                        _c("Card", [
                          _c("h5", [_vm._v("Contact Info")]),
                          _vm._v(" "),
                          _c("form", [
                            _c("fieldset", [
                              _c("label", { staticClass: "scenario-label" }, [
                                _vm._v("First Name")
                              ]),
                              _vm._v(" "),
                              _vm.isEditing
                                ? _c(
                                    "span",
                                    { staticClass: "scenario-value" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.activeRowLocal.first_name,
                                            expression:
                                              "activeRowLocal.first_name"
                                          }
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.activeRowLocal.first_name
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.activeRowLocal,
                                              "first_name",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.activeRowLocal.first_name)
                                    )
                                  ])
                            ]),
                            _vm._v(" "),
                            _c("fieldset", [
                              _c("label", { staticClass: "scenario-label" }, [
                                _vm._v("Last Name")
                              ]),
                              _vm._v(" "),
                              _vm.isEditing
                                ? _c(
                                    "span",
                                    { staticClass: "scenario-value" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.activeRowLocal.last_name,
                                            expression:
                                              "activeRowLocal.last_name"
                                          }
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.activeRowLocal.last_name
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.activeRowLocal,
                                              "last_name",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(_vm.activeRowLocal.last_name))
                                  ])
                            ]),
                            _vm._v(" "),
                            _c("fieldset", [
                              _c("label", { staticClass: "scenario-label" }, [
                                _vm._v("Email")
                              ]),
                              _vm._v(" "),
                              _vm.isEditing
                                ? _c(
                                    "span",
                                    { staticClass: "scenario-value" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.activeRowLocal.email,
                                            expression: "activeRowLocal.email"
                                          }
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.activeRowLocal.email
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.activeRowLocal,
                                              "email",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(_vm.activeRowLocal.email))
                                  ])
                            ]),
                            _vm._v(" "),
                            _c("fieldset", [
                              _c("label", { staticClass: "scenario-label" }, [
                                _vm._v("Phone")
                              ]),
                              _vm._v(" "),
                              _vm.isEditing
                                ? _c(
                                    "span",
                                    { staticClass: "scenario-value" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.activeRowLocal.phone,
                                            expression: "activeRowLocal.phone"
                                          }
                                        ],
                                        attrs: { type: "text" },
                                        domProps: {
                                          value: _vm.activeRowLocal.phone
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.activeRowLocal,
                                              "phone",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(_vm.activeRowLocal.phone))
                                  ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("Card", [
                          _c("h5", [
                            _vm._v(
                              "\n                Loan Scenario\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("Loan Purpose")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.activeRowLocal.quote_info
                                              .loan_purpose,
                                          expression:
                                            "activeRowLocal.quote_info.loan_purpose"
                                        }
                                      ],
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.activeRowLocal.quote_info,
                                            "loan_purpose",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "purchase" } },
                                        [_vm._v("Purchase")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "refinance" } },
                                        [_vm._v("Refinance")]
                                      )
                                    ]
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info.loan_purpose
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _vm.activeRowLocal.quote_info.loan_purpose ==
                          "refinance"
                            ? _c("fieldset", [
                                _c("label", { staticClass: "scenario-label" }, [
                                  _vm._v("Cash Out")
                                ]),
                                _vm._v(" "),
                                _vm.isEditing
                                  ? _c(
                                      "span",
                                      { staticClass: "scenario-value" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.activeRowLocal.quote_info
                                                  .cash_out,
                                              expression:
                                                "activeRowLocal.quote_info.cash_out"
                                            }
                                          ],
                                          attrs: { type: "text" },
                                          domProps: {
                                            value:
                                              _vm.activeRowLocal.quote_info
                                                .cash_out
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.activeRowLocal.quote_info,
                                                "cash_out",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.activeRowLocal.quote_info.cash_out
                                        )
                                      )
                                    ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("Loan Type")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.activeRowLocal.quote_info
                                              .loan_type,
                                          expression:
                                            "activeRowLocal.quote_info.loan_type"
                                        }
                                      ],
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.activeRowLocal.quote_info,
                                            "loan_type",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "conventional" } },
                                        [_vm._v("Conventional")]
                                      ),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "va" } }, [
                                        _vm._v("VA")
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "usda" } },
                                        [_vm._v("USDA")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "fha" } },
                                        [_vm._v("FHA")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "arm" } },
                                        [_vm._v("ARM")]
                                      )
                                    ]
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info.loan_type
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("Loan Term")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.activeRowLocal.quote_info
                                              .loan_term,
                                          expression:
                                            "activeRowLocal.quote_info.loan_term"
                                        }
                                      ],
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.activeRowLocal.quote_info,
                                            "loan_term",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "30" } }, [
                                        _vm._v("30 Year Fixed")
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "20" } }, [
                                        _vm._v("20 Year Fixed")
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "15" } }, [
                                        _vm._v("15 Year Fixed")
                                      ])
                                    ]
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info.loan_term
                                        ? _vm.activeRowLocal.quote_info
                                            .loan_term > 40
                                          ? _vm.activeRowLocal.quote_info
                                              .loan_term / 12
                                          : _vm.activeRowLocal.quote_info
                                              .loan_term
                                        : ""
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("ARM Term")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.activeRowLocal.quote_info
                                              .arm_term,
                                          expression:
                                            "activeRowLocal.quote_info.arm_term"
                                        }
                                      ],
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.activeRowLocal.quote_info,
                                            "arm_term",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("N/A")
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "5" } }, [
                                        _vm._v("5/1 ARM")
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "7" } }, [
                                        _vm._v("7/1 ARM")
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "10" } }, [
                                        _vm._v("10/1 ARM")
                                      ])
                                    ]
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info.arm_term
                                        ? _vm.activeRowLocal.quote_info
                                            .arm_term + "/1 ARM"
                                        : "None"
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("Loan Amount")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.activeRowLocal.quote_info
                                            .loan_amount,
                                        expression:
                                          "activeRowLocal.quote_info.loan_amount"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.activeRowLocal.quote_info
                                          .loan_amount
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.activeRowLocal.quote_info,
                                          "loan_amount",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info.loan_amount
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("Property Value")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.activeRowLocal.quote_info
                                            .list_price,
                                        expression:
                                          "activeRowLocal.quote_info.list_price"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.activeRowLocal.quote_info.list_price
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.activeRowLocal.quote_info,
                                          "list_price",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info.list_price
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("Credit Score")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.activeRowLocal.quote_info
                                            .credit_score,
                                        expression:
                                          "activeRowLocal.quote_info.credit_score"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.activeRowLocal.quote_info
                                          .credit_score
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.activeRowLocal.quote_info,
                                          "credit_score",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info.credit_score
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("Property Type")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.activeRowLocal.quote_info
                                              .property_type,
                                          expression:
                                            "activeRowLocal.quote_info.property_type"
                                        }
                                      ],
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.activeRowLocal.quote_info,
                                            "property_type",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: { value: "single_family_home" }
                                        },
                                        [_vm._v("Single Family Home")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "townhome" } },
                                        [_vm._v("Townhome")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "condominium" } },
                                        [_vm._v("Condominium")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        {
                                          attrs: { value: "multi_tenant_home" }
                                        },
                                        [_vm._v("Multi Family")]
                                      )
                                    ]
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info
                                        .property_type
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("Property Usage")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.activeRowLocal.quote_info
                                              .residency_type,
                                          expression:
                                            "activeRowLocal.quote_info.residency_type"
                                        }
                                      ],
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.activeRowLocal.quote_info,
                                            "residency_type",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "primary_home" } },
                                        [_vm._v("Primary Residence")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "second_home" } },
                                        [_vm._v("Secondary Residence")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "rental_home" } },
                                        [_vm._v("Rental")]
                                      )
                                    ]
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info
                                        .residency_type
                                    )
                                  )
                                ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("Card", [
                          _c("h5", [_vm._v("Property Location")]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("Street Address")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.activeRowLocal.quote_info.location
                                            .street_address,
                                        expression:
                                          "activeRowLocal.quote_info.location.street_address"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.activeRowLocal.quote_info.location
                                          .street_address
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.activeRowLocal.quote_info
                                            .location,
                                          "street_address",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info.location
                                        .street_address
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("City")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.activeRowLocal.quote_info.location
                                            .city,
                                        expression:
                                          "activeRowLocal.quote_info.location.city"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.activeRowLocal.quote_info.location
                                          .city
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.activeRowLocal.quote_info
                                            .location,
                                          "city",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info.location
                                        .city
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("State")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.activeRowLocal.quote_info.location
                                            .state,
                                        expression:
                                          "activeRowLocal.quote_info.location.state"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.activeRowLocal.quote_info.location
                                          .state
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.activeRowLocal.quote_info
                                            .location,
                                          "state",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info.location
                                        .state
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("County")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.activeRowLocal.quote_info.location
                                            .county,
                                        expression:
                                          "activeRowLocal.quote_info.location.county"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.activeRowLocal.quote_info.location
                                          .county
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.activeRowLocal.quote_info
                                            .location,
                                          "county",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info.location
                                        .county
                                    )
                                  )
                                ])
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("Zip Code")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.activeRowLocal.quote_info.location
                                            .zipcode,
                                        expression:
                                          "activeRowLocal.quote_info.location.zipcode"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.activeRowLocal.quote_info.location
                                          .zipcode
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.activeRowLocal.quote_info
                                            .location,
                                          "zipcode",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeRowLocal.quote_info.location
                                        .zipcode
                                    )
                                  )
                                ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("Card", [
                          _c("h5", [_vm._v("Loan Officer Fields")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "15px" } },
                            [
                              _c("fieldset", [
                                _c("label", { staticClass: "scenario-label" }, [
                                  _vm._v("Loan Officer")
                                ]),
                                _vm._v(" "),
                                _vm.isEditing
                                  ? _c(
                                      "span",
                                      { staticClass: "scenario-value" },
                                      [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.activeRowLocal.loid,
                                                expression:
                                                  "activeRowLocal.loid"
                                              }
                                            ],
                                            staticClass: "select-lo",
                                            attrs: { id: "select-lo" },
                                            on: {
                                              change: [
                                                function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.activeRowLocal,
                                                    "loid",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                                _vm.selectLo
                                              ]
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: {
                                                  disabled: "",
                                                  value: ""
                                                }
                                              },
                                              [_vm._v("Please select one")]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(_vm.los, function(lo) {
                                              return _c(
                                                "option",
                                                {
                                                  key: lo.name,
                                                  domProps: { value: lo.uid }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(lo.name) +
                                                      "\n                        "
                                                  )
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.activeRowLocal.lo.name))
                                    ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("Annual Taxes")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.activeRowLocal.quote_info
                                            .admin_fields.taxes,
                                        expression:
                                          "activeRowLocal.quote_info.admin_fields.taxes"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.activeRowLocal.quote_info
                                          .admin_fields.taxes
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.activeRowLocal.quote_info
                                            .admin_fields,
                                          "taxes",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              : _vm.activeRowLocal.quote_info.admin_fields &&
                                _vm.activeRowLocal.quote_info.admin_fields
                                  .taxes &&
                                _vm.activeRowLocal.quote_info.admin_fields
                                  .taxes > 0
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.activeRowLocal.quote_info
                                          .admin_fields.taxes
                                      )
                                    )
                                  ])
                                : _vm._e(),
                            _vm._v("% | $"),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  Number(
                                    _vm.activeRowLocal.quote_info.admin_fields
                                      .taxes /
                                      100 *
                                      _vm.activeRowLocal.quote_info.list_price
                                  ).toFixed(2)
                                ) + "/yr"
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("fieldset", [
                            _c("label", { staticClass: "scenario-label" }, [
                              _vm._v("Annual Home Insurance")
                            ]),
                            _vm._v(" "),
                            _vm.isEditing
                              ? _c("span", { staticClass: "scenario-value" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.activeRowLocal.quote_info
                                            .admin_fields.insurance,
                                        expression:
                                          "activeRowLocal.quote_info.admin_fields.insurance"
                                      }
                                    ],
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.activeRowLocal.quote_info
                                          .admin_fields.insurance
                                    },
                                    on: {
                                      change: _vm.fieldChange,
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.activeRowLocal.quote_info
                                            .admin_fields,
                                          "insurance",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              : _vm.activeRowLocal.quote_info.admin_fields &&
                                _vm.activeRowLocal.quote_info.admin_fields
                                  .insurance &&
                                _vm.activeRowLocal.quote_info.admin_fields
                                  .insurance > 0
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.activeRowLocal.quote_info
                                          .admin_fields.insurance
                                      )
                                    )
                                  ])
                                : _vm._e(),
                            _vm._v("% | $"),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  Number(
                                    _vm.activeRowLocal.quote_info.admin_fields
                                      .insurance /
                                      100 *
                                      _vm.activeRowLocal.quote_info.list_price
                                  ).toFixed(2)
                                ) + "/yr"
                              )
                            ])
                          ])
                        ])
                      ],
                      1
                    )
                  : _c("div", [_vm._v("\n            Loading...\n          ")])
              ])
            ]),
            _vm._v(" "),
            _c("Tab", { attrs: { name: "Details" } }, [
              _c(
                "div",
                { staticClass: "editorContainer" },
                [
                  _vm.isLoading
                    ? _c("LoaderPlaceholder", { attrs: { type: "circle" } })
                    : _c("vjsoneditor", {
                        attrs: { options: _vm.options, plus: true },
                        model: {
                          value: _vm.activeRowLocal,
                          callback: function($$v) {
                            _vm.activeRowLocal = $$v
                          },
                          expression: "activeRowLocal"
                        }
                      })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "Tab",
              { staticClass: "emails-tab", attrs: { name: "Emails" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "container",
                    staticStyle: { "padding-top": "15px" }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      !_vm.isLoading
                        ? _c("div", [
                            _c("div", { staticClass: "col-md-12" }, [
                              _vm.emailLog && _vm.emailLog.length
                                ? _c("div", [
                                    _c(
                                      "ul",
                                      { staticClass: "list-group" },
                                      _vm._l(_vm.emailLog, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "li",
                                          { staticClass: "list-group-item" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  target: "_blank",
                                                  href:
                                                    "/view-email/" +
                                                    parseInt(
                                                      item.email_id
                                                    ).toString(36)
                                                }
                                              },
                                              [_vm._v("Rate Alert")]
                                            ),
                                            _vm._v(
                                              " @ " +
                                                _vm._s(
                                                  new Date(
                                                    item.timestamp * 1000
                                                  ).toLocaleString()
                                                ) +
                                                "\n                      "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "badge" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.status == "2"
                                                      ? "Opened"
                                                      : item.status == "3"
                                                        ? "Clicked"
                                                        : "Unopened"
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    )
                                  ])
                                : _c("div", [
                                    _c("div", { staticClass: "well" }, [
                                      _vm._v(
                                        "No rate alerts have been sent yet."
                                      )
                                    ])
                                  ])
                            ])
                          ])
                        : _c("div", [
                            _vm._v("\n              Loading...\n            ")
                          ])
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "Tab",
              { staticClass: "events-tab", attrs: { name: "History" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "container",
                    staticStyle: { "padding-top": "15px" }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      !_vm.isLoading
                        ? _c("div", [
                            _c("div", { staticClass: "col-md-12" }, [
                              _vm.eventsLog && _vm.eventsLog.length
                                ? _c("div", [
                                    _c(
                                      "ul",
                                      { staticClass: "list-group" },
                                      _vm._l(_vm.eventsLog, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "li",
                                          { staticClass: "list-group-item" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.humanize(item.type))
                                              )
                                            ]),
                                            _vm._v(
                                              " @ " +
                                                _vm._s(
                                                  new Date(
                                                    item.timestamp * 1000
                                                  ).toLocaleString()
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      })
                                    )
                                  ])
                                : _c("div", [
                                    _c("div", { staticClass: "well" }, [
                                      _vm._v(
                                        "There are no events for this subscription yet."
                                      )
                                    ])
                                  ])
                            ])
                          ])
                        : _c("div", [
                            _vm._v("\n              Loading...\n            ")
                          ])
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "Tab",
              { staticClass: "events-tab", attrs: { name: "Followup" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "container",
                    staticStyle: { "padding-top": "15px" }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      !_vm.isLoading
                        ? _c("div", [
                            _c("div", { staticClass: "col-md-12" }, [
                              _vm.smsThread
                                ? _c(
                                    "div",
                                    [
                                      _c("Chat", {
                                        attrs: {
                                          messages: _vm.smsThread.messages,
                                          "send-message": _vm.threadReply
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c("div", [
                                    !_vm.creatingThread
                                      ? _c("div", [
                                          _c(
                                            "button",
                                            { on: { click: _vm.createThread } },
                                            [_vm._v("Send SMS Followup")]
                                          )
                                        ])
                                      : _c("div", [
                                          _vm._v("Sending Followup...")
                                        ])
                                  ])
                            ])
                          ])
                        : _c("div", [
                            _vm._v("\n              Loading...\n            ")
                          ])
                    ])
                  ]
                )
              ]
            )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-modern closeBtn",
          on: { click: _vm.closePanel }
        },
        [_vm._v("Close")]
      ),
      _vm._v(" "),
      _vm.formChanged
        ? _c(
            "button",
            {
              staticClass: "btn btn-modern saveBtn",
              on: { click: _vm.saveSubscription }
            },
            [_vm._v("Save")]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-712084cb", { render: render, staticRenderFns: staticRenderFns })
  }
}