var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { staticClass: "container chat-app" }, [
      _c("div", { staticClass: "chat-box" }, [
        _c(
          "div",
          { staticClass: "chat-history" },
          _vm._l(_vm.messages, function(message) {
            return _c("div", { key: message["_id"] }, [
              _c(
                "div",
                {
                  staticClass: "message-wrapper",
                  class: {
                    "text-right is-user": _vm.formatMessage(message).isUser
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-items-center",
                      class: {
                        "flex-row-reverse": _vm.formatMessage(message).isUser
                      }
                    },
                    [
                      _c("div", [
                        _c("div", {
                          staticClass: "message-content",
                          class: {
                            "bg-primary text-white": _vm.formatMessage(message)
                              .isUser
                          },
                          domProps: {
                            innerHTML: _vm._s(_vm.formatMessage(message).text)
                          }
                        }),
                        _vm._v(" "),
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(_vm.formatMessage(message).timestamp))
                        ])
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { clear: "both" } })
            ])
          })
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }, [
          _c("div", { staticClass: "chat-input" }, [
            _c("div", { staticClass: "input-wrapper" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newMessage,
                    expression: "newMessage"
                  }
                ],
                attrs: { placeholder: "" },
                domProps: { value: _vm.newMessage },
                on: {
                  keydown: [
                    function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.sendMessageInternal($event)
                    },
                    function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.newLine($event)
                    }
                  ],
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newMessage = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "button-wrapper" }, [
              _c(
                "button",
                {
                  staticClass: "chat-send",
                  on: { click: _vm.sendMessageInternal }
                },
                [_c("v-icon", { attrs: { name: "paper-plane" } })],
                1
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-23621e5c", { render: render, staticRenderFns: staticRenderFns })
  }
}